import React from "react";
// comment
const App = () => {
  return (
    <div className="App">
      <h1 className="text-5xl underline text-center m-5 ml-10 bg-red-100 p-5">Image Gallery</h1>
    </div>
  );
};

export default App;
